.full-width {
  width: 100%;
  height: 100%;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.empty-state img {
  width: 150px;
  opacity: 0.7;
  margin-bottom: 25px;
}
