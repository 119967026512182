body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}
.full-width {
  width: 100%;
  height: 100%;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.empty-state img {
  width: 150px;
  opacity: 0.7;
  margin-bottom: 25px;
}

